import request from './request.js'
export function deleteCardRecordList(params) {
    return request({
        url: 'record/deleteCardRecord/list.do?',
        method: 'post',
        data:params
    })
}

// 网卡删除导出
export function exportDeleteCardRecord(params) {
    return request({
        url: 'record/deleteCardRecord/exportToExcelSync.do?',
        method: 'post',
        data:params
    })
}

// 过期时间变更记录
export function expraTimeChangeList(params) {
    return request({
        url: 'card/listExpireRecord.do?',
        method: 'post',
        data:params
    })
}
// 过期时间变更记录详情
export function expraTimeChangeDetail(params) {
    return request({
        url: 'card/initExpireRecord.do?',
        method: 'post',
        data:params
    })
}
// 导出过期时间
export function exportExpraTimeChange(params) {
    return request({
        url: 'card/exportExpireRecordSync.do?',
        method: 'post',
        data:params
    })
}

// 下载导出记录
export function downloadExportList(params) {
    return request({
        url: 'record/importExportRecord/list.do?',
        method: 'post',
        data:params
    })
}


// 删除下载导出数据
export function deleteDownloadExport(params) {
    return request({
        url: 'record/importExportRecord/deleteById.do?',
        method: 'post',
        data:params
    })
}

// 网卡分销记录
// 分销列表
export function distributeNetworkCard(params) {
    return request ({
        url:'distributionagentcard/list.do?',
        method:'post',
        data:params
    })
}

// 分销失败列表
export function distributeFail(params) {
    return request ({
        url:'card/distributioncardfail/list.do?',
        method:'post',
        data:params
    })
}

// 分校失败详情
export function distributeFailDetail(params) {
    return request ({
        url:'card/distributioncardfail/findById.do?',
        method:'post',
        data:params
    })
}



// 停复机记录
// 获取停复机记录列表
export function shutdownRecord(params) {
    return request ({
        url:'record/stopOrStartRecord/list.do?',
        method:'post',
        data:params
    })
}


// 停复机失败详情
export function shutdownRecordDetail(params) {
    return request ({
        url:'record/stopOrStartRecord/viewDetail.do?',
        method:'post',
        data:params
    })
}

// 实名导入
export function realNameImport(params) {
    return request ({
        url:'record/realNameRecord/list.do?',
        method:'post',
        data:params
    })
}

// 实名导入excel
export function realNameAddExcel(params) {
    return request ({
        url:'record/realNameRecord/addByExcelSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 实名批量删除
export function realNameDelExcel(params) {
    return request ({
        url:'record/realNameRecord/deleteByExcelSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 实名导入删除
export function realNameDelete(params) {
    return request ({
        url:'record/realNameRecord/deleteById.do?',
        method:'post',
        data:params
    })
}

// 网卡回收记录
// 网卡回收列表
export function NICrRecovery(params) {
    return request ({
        url:'record/cardRecycleRecord/list.do?',
        method:'post',
        data:params
    })
}

// 套餐变更
export function mealChange(params) {
    return request ({
        url:'record/setMealChange/list.do?',
        method:'post',
        data:params
    })
}

// 导出套餐变更
export function exportPackageChange(params) {
    return request ({
        url:'record/setMealChange/exportToExcelSync.do?',
        method:'post',
        data:params
    })
}

// 工单转单记录

export function changeWorkOrder(params) {
    return request ({
        url:'record/changeWorkOrder/list.do?',
        method:'post',
        data:params
    })
}

// 网卡激活记录
export function activeCard(params) {
    return request ({
        url:'record/activeCardRecord/list.do?',
        method:'post',
        data:params
    })
}


// 套餐分配
export function allocatePackage(params) {
    return request ({
        url:'record/distribution/list.do?',
        method:'post',
        data:params
    })
}

// 窜卡记录
export function cardRecording(params) {
    return request ({
        url:'record/cuanKaRecord/list.do?',
        method:'post',
        data:params
    })
}

// 导出窜卡记录
export function exportCardRecord(params) {
    return request ({
        url:'record/cuanKaRecord/exportToExcelSync.do?',
        method:'post',
        data:params
    })
}

// 网卡注销记录
export function cancelCardRecord(params) {
    return request ({
        url:'record/cancelCardRecord/list.do?',
        method:'post',
        data:params
    })
}

// 不续费充值
export function rechargeWithoutRenewal(params) {
    return request ({
        url:'record/notNeedRechargeRecord/list.do?',
        method:'post',
        data:params
    })
}

// 删除by id
export function deleteRechargeWithoutRenewal(params) {
    return request ({
        url:'record/notNeedRechargeRecord/deleteById.do?',
        method:'post',
        data:params
    })
}

// 批量删除
export function deleteRechargeWithoutRenewalByExcel(params) {
    return request ({
        url:'record/notNeedRechargeRecord/deleteByExcelSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}


// 批量停机
export function batchStopCard(params) {
    return request ({
        url:'record/batchStopCardRecord/list.do?',
        method:'post',
        data:params,
    })
}

// 导出批量停机
export function exportBatchStopCard(params) {
    return request ({
        url:'record/batchStopCardRecord/exportToExcelSync.do?',
        method:'post',
        data:params,
    })
}

// 删除批量停机记录ById
export function delBatchStopCardRecordById(params) {
    return request ({
        url:'record/batchStopCardRecord/deleteById.do?',
        method:'post',
        data:params,
    })
}


// 批量停机记录批量导入
export function importBatchStopCardRecordByExcel(params) {
    return request ({
        url:'record/batchStopCardRecord/importByExcelSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}


// 批量停机记录批量删除
export function deleteBatchStopCardRecordByExcel(params) {
    return request ({
        url:'record/batchStopCardRecord/deleteByExcelSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 转代理记录
// 转代理记录列表
export function changeAgentRecord(params) {
    return request ({
        url:'record/changeAgentRecord/list.do?',
        method:'post',
        data:params,
    })
}


// 代理商公众号充值记录
export function agentPublicRecharge(params) {
    return request ({
        url:'order/agentwechatrechargeorder/gridCard.do?',
        method:'post',
        data:params,
    })
}

// 导出
export function exportAgentPublicRecharge(params) {
    return request ({
        url:'order/agentwechatrechargeorder/export2excelSync.do?',
        method:'post',
        data:params,
    })
}

// 删除代理商公众号充值记录BY ID
export function deleteAgentPublicRechargeById(params) {
    return request ({
        url:'agentwxrechargeorder/deleteCard.do?',
        method:'post',
        data:params,
    })
}


// 代理商公众号充值记录批量导入
export function importAgentPublicRechargeByExcel(params) {
    return request ({
        url:'order/agentwechatrechargeorder/importSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 代理商公众号充值记录批量删除
export function deleteAgentPublicRechargeByExcel(params) {
    return request ({
        url:'order/agentwechatrechargeorder/import2Sync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

// 单卡限速记录
// 单卡限速列表
export function singleLimitSpeed(params) {
    return request ({
        url:'record/singleLimitSpeed/list.do?',
        method:'post',
        data:params,
    })
}

// 导出 单卡限速
export function exportSingleLimitSpeed(params) {
    return request ({
        url:'record/singleLimitSpeed/exportToExcelSync.do?',
        method:'post',
        data:params,
    })
}

// 导出限速记录
export function exportSpeedLimit(params) {
    return request ({
        url:'record/limitSpeedRecord/exportToExcelSync.do?',
        method:'post',
        data:params,
    })
}

export function speedLimit(params) {
    return request ({
        url:'record/limitSpeedRecord/list.do?',
        method:'post',
        data:params,
    })
}


// 流量增减记录
export function modifyTraffic(params) {
    return request({
        url:'record/updateFlowRecord/list.do?',
        method:'post',
        data:params,
    })
}

// 语音卡订购清单
// 语音卡清单表
export function voiceRechargeRecord(params) {
    return request({
        url:'record/voiceRechargeRecord/list.do?',
        method:'post',
        data:params,
    })
}

// 语音卡导出
export function exportVoiceOrder(params) {
    return request({
        url:'record/voiceRechargeRecord/exportToExcelSync.do?',
        method:'post',
        data:params,
    })
}

// 智能停复机记录
export function smartStopRecord(params) {
    return request({
        url:'smartStop/record/list.do?',
        method:'post',
        data:params,
    })
}

// 导出智能停复机
export function exportSmartStopRecord(params) {
    return request({
        url:'smartStop/record/exportSync.do?',
        method:'post',
        data:params,
    })
}

// 改价记录
// 代理商列表
export function changePriceAgentList(params) {
    return request({
        url:'agent/list4agents.do?',
        method:'post',
        data:params,
    })
}

export function changePriceList(params) {
    return request({
        url:'agentPrice/getPageList.do?',
        method:'post',
        data:params,
    })
}

//voiceWhitelist.vue
export function voiceWhitelist(params) {
    return request({
        url:'voiceWhitelist/grid.do?',
        method:'post',
        data:params,
    })
}
export function voiceWhitelistEeportSync(params) {
    return request({
        url:'voiceWhitelist/exportSync.do?',
        method:'post',
        data:params,
    })
}

//stopReason.vue
export function stopReasonlist(params) {
    return request({
        url:'stopReason/list.do?',
        method:'post',
        data:params,
    })
}
export function stopReasonExportSync(params) {
    return request({
        url:'stopReason/exportSync.do?',
        method:'post',
        data:params,
    })
}
export function stopReasonImportSync(params) {
    return request ({
        url:'stopReason/importSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

//ImaginarySwitching.vue
export function getAccountList(){
    return request({
        url: '/system/cardOperator/getAccountListByAgent.do?',
        method: 'post'
    })
}

export function imaginarySwitchList(params) {
    return request({
        url:'flowSwitchLog/list.do?',
        method:'post',
        data:params,
    })
}
export function imaginarySwitchExportSync(params) {
    return request({
        url:'flowSwitchLog/exportSync.do?',
        method:'post',
        data:params,
    })
}
export function imaginarySwitchEdit(params) {
    return request({
        url:'flowSwitchLog/update.do?',
        method:'post',
        data:params,
    })
}


// cardAbnormalState.vue
export function getCardAbnormalStateList(params) {
    return request({
        url:'abnormal/list.do?',
        method:'post',
        data:params,
    })
}

export function cardAbnormalImportSync(params) {
    return request ({
        url:'abnormal/importSync.do?',
        method:'post',
        data:params,
        timeout:20000,
		headers:{'Content-Type': 'multipart/form-data'}
    })
}

export function cardAbnormalExportSync(params) {
    return request({
        url:'abnormal/exportSync.do?',
        method:'post',
        data:params,
    })
}

// 立即生效
export function imEffectList(params) {
    return request({
        url:'/record/imEffect.do?',
        method:'post',
        data:params,
    })
}

// 操作变更列表
export function updateOperationList(params) {
    return request({
        url:'cardEvent/list.do?',
        method:'get',
        params:params,
    })
}

export function findUpdateOperation(params) {
    return request({
        url:'cardEvent/findOne.do?',
        method:'post',
        data:params,
    })
}

export function editUpdateOperation(params) {
    return request({
        url:'cardEvent/edit.do?',
        method:'post',
        data:params,
    })
}

export function deleteUpdateOperation(params) {
    return request({
        url:'cardEvent/delete.do?',
        method:'post',
        data:params,
    })
}

export function exportUpdateOperation(params) {
    return request({
        url:'cardEvent/export.do?',
        method:'post',
        data:params,
    })
}

export function updateOperationLog(params) {
    return request({
        url:'cardEventLog/list.do?',
        method:'get',
        params: params,
    })
}

export function addVoiceWhite(params) {//新增白名单
    return request({
        url:'/card/addWhiteNumber.do?',
        method:'get',
        params: params,
    })
}

export function deleteVoiceWhite(params) {//删除白名单
    return request({
        url:'/card/delWhiteNumber.do?',
        method:'get',
        params: params,
    })
}




